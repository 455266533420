import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy } from 'react-table';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { getFinishedGoods, getFinishedGoodsPhoenixClasses, getFinishedGoodsStatuses } from '../api/finishedGoodsApi';
import { Confidential } from './Confidential';

const FinishedGoodsTable = () => {
  const [data, setData] = useState([]);
  const [phoenixClasses, setPhoenixClasses] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedPhoenixClass, setSelectedPhoenixClass] = useState({ value: 'ALL', label: 'ALL' });
  const [selectedStatus, setSelectedStatus] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const phoenixClass = selectedPhoenixClass ? selectedPhoenixClass.value : null;
      const status = selectedStatus ? selectedStatus.value : null;
      const fetchedData = await getFinishedGoods(phoenixClass, status);
      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedPhoenixClass, selectedStatus]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const phoenixClassesData = await getFinishedGoodsPhoenixClasses();
        const statusesData = await getFinishedGoodsStatuses();
        
        // Remove duplicate 'ALL' if it exists in the API response
        const uniquePhoenixClasses = ['ALL', ...phoenixClassesData.filter(pc => pc !== 'ALL')];
        setPhoenixClasses(uniquePhoenixClasses);
        setStatuses(statusesData);
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };
    fetchFilterOptions();
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    console.log('Data changed:', data);
  }, [data]);

  console.log('Data received:', data);

  const defaultColumns = [
    'isku', 'asin', 'phoenix_class', 'brand', 'material_cost', 
    'labor_cost', 'total_unit_cost', 'amz_safety_days', 
    'wh_safety_days', 'lead_time', 'status'
  ];

  const allColumns = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid or empty data received');
      return [];
    }

    const sampleItem = data[0];
    return Object.keys(sampleItem).map(key => ({
      Header: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
      accessor: key,
    }));
  }, [data]);

  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);

  const columns = useMemo(
    () => allColumns.filter(column => selectedColumns.includes(column.accessor)).map(column => {
      if (column.accessor === 'isku') {
        return {
          ...column,
          Cell: ({ value }) => (
            <Link 
              to={`/finished-goods/${value}`} 
              style={{ 
                color: 'white', 
                textDecoration: 'underline', 
                textUnderlineOffset: '2px'
              }}
            >
              {value}
            </Link>
          )
        };
      }
      return column;
    }),
    [allColumns, selectedColumns]
  );

  const filterPhoenixClass = (rows, id, filterValue) => {
    console.log('Filtering with value:', filterValue);
    return rows.filter(row => {
      const rowValue = row.values[id];
      console.log(`Row ${row.id} - Phoenix Class: "${rowValue}", Type: ${typeof rowValue}`);
      if (filterValue === 'ALL') {
        const result = rowValue != null && rowValue !== '' && rowValue !== undefined;
        console.log(`ALL filter - Row ${row.id}: ${result}`);
        return result;
      } else if (filterValue === 'None') {
        const result = rowValue == null || rowValue === '' || rowValue === undefined;
        console.log(`None filter - Row ${row.id}: ${result}`);
        return result;
      }
      const result = rowValue === filterValue;
      console.log(`Specific filter (${filterValue}) - Row ${row.id}: ${result}`);
      return result;
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { 
        sortBy: [{ id: 'phoenix_class', desc: false }],
        filters: [] // Ensure no initial filter is set
      },
      filterTypes: {
        phoenixClass: filterPhoenixClass,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    console.log('Current filters:', rows.filter);
  }, [rows.filter]);

  const handleColumnChange = (selectedOption) => {
    const columnAccessor = selectedOption.value;
    setSelectedColumns(prevSelected => 
      prevSelected.includes(columnAccessor)
        ? prevSelected.filter(col => col !== columnAccessor)
        : [...prevSelected, columnAccessor]
    );
  };

  const handlePhoenixClassChange = (selectedOption) => {
    console.log('Selected Phoenix Class:', selectedOption);
    setSelectedPhoenixClass(selectedOption);
    fetchData();
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    fetchData();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '200px',
      height: '38px',
      backgroundColor: '#374151',
      borderColor: '#4B5563',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1F2937',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#F9FAFB' : '#D1D5DB',
      backgroundColor: state.isSelected ? '#3B82F6' : state.isFocused ? '#374151' : '#1F2937',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#F9FAFB',
    }),
  };

  const isConfidentialColumn = (columnId) => {
    if (!columnId) return false;
    const confidentialColumns = ['isku', 'asin', 'brand'];
    return confidentialColumns.includes(String(columnId).toLowerCase());
  };

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <div className="finished-goods-controls">
        <input
          type="text"
          placeholder="Search..."
          onChange={e => setGlobalFilter(e.target.value)}
          className="search-input"
        />
        <Select
          options={allColumns.map(col => ({ 
            value: col.accessor, 
            label: col.Header,
          }))}
          onChange={handleColumnChange}
          styles={customStyles}
          isMulti={false}
          placeholder="Choose Columns"
          value={null}
          formatOptionLabel={({ label, value }) => (
            <div>
              {selectedColumns.includes(value) ? '✓ ' : ''}
              {label}
            </div>
          )}
        />
        <Select
          options={phoenixClasses.map(pc => ({ value: pc, label: pc }))}
          onChange={handlePhoenixClassChange}
          styles={customStyles}
          isClearable={false}
          placeholder="Filter Phoenix Class"
          value={selectedPhoenixClass}
        />
        <Select
          options={statuses.map(status => ({ value: status, label: status }))}
          onChange={handleStatusChange}
          styles={customStyles}
          isClearable
          placeholder="Filter Status"
          value={selectedStatus}
        />
      </div>
      <table {...getTableProps()} className="finished-goods-table">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={j}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  if (cell.column.id === 'isku') {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        <Confidential>
                          <Link 
                            to={`/finished-goods/${cell.value}`}
                            style={{ 
                              color: 'white', 
                              textDecoration: 'underline', 
                              textUnderlineOffset: '2px'
                            }}
                          >
                            {cell.value}
                          </Link>
                        </Confidential>
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {isConfidentialColumn(cell.column.id) ? (
                        <Confidential>
                          {cell.render('Cell')}
                        </Confidential>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FinishedGoodsTable;
