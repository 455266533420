import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import './Sidebar.css';
import { usePresenterMode } from '../context/PresenterModeContext';

const Sidebar = ({ onCollapse, isCollapsed }) => {
  const [salesDropdownOpen, setSalesDropdownOpen] = React.useState(false);
  const [finishedGoodsDropdownOpen, setFinishedGoodsDropdownOpen] = React.useState(false);
  const [bomsDropdownOpen, setBomsDropdownOpen] = React.useState(false);
  const [inventoryDropdownOpen, setInventoryDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isPresenterMode, setIsPresenterMode } = usePresenterMode();

  const toggleSalesDropdown = () => setSalesDropdownOpen(!salesDropdownOpen);
  const toggleFinishedGoodsDropdown = () => setFinishedGoodsDropdownOpen(!finishedGoodsDropdownOpen);
  const toggleBomsDropdown = () => setBomsDropdownOpen(!bomsDropdownOpen);
  const toggleInventoryDropdown = () => setInventoryDropdownOpen(!inventoryDropdownOpen);
  
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <ul className="sidebar-menu">
          <li><Link to="/"><span>Home</span></Link></li>
          <li className="has-submenu">
            <button onClick={toggleSalesDropdown} className="dropdown-toggle">
              <span>Sales</span>
              <span className={`arrow ${salesDropdownOpen ? 'open' : ''}`}></span>
            </button>
            {salesDropdownOpen && !isCollapsed && (
              <ul className="submenu">
                <li className="submenu-item"><Link to="/sales-report">Sales Report</Link></li>
                <li className="submenu-item"><Link to="/sales-dashboard">Sales Dashboard</Link></li>
              </ul>
            )}
          </li>
          <li className="has-submenu">
            <button onClick={toggleFinishedGoodsDropdown} className="dropdown-toggle">
              <span>Finished Goods</span>
              <span className={`arrow ${finishedGoodsDropdownOpen ? 'open' : ''}`}></span>
            </button>
            {finishedGoodsDropdownOpen && !isCollapsed && (
              <ul className="submenu">
                <li className="submenu-item"><Link to="/isku-list">iSKU List</Link></li>
              </ul>
            )}
          </li>
          <li className="has-submenu">
            <button onClick={toggleBomsDropdown} className="dropdown-toggle">
              <span>BOMs</span>
              <span className={`arrow ${bomsDropdownOpen ? 'open' : ''}`}></span>
            </button>
            {bomsDropdownOpen && !isCollapsed && (
              <ul className="submenu">
                <li className="submenu-item"><Link to="/bom-list">BOM List</Link></li>
              </ul>
            )}
          </li>
          <li className="has-submenu">
            <button onClick={toggleInventoryDropdown} className="dropdown-toggle">
              <span>Inventory</span>
              <span className={`arrow ${inventoryDropdownOpen ? 'open' : ''}`}></span>
            </button>
            {inventoryDropdownOpen && !isCollapsed && (
              <ul className="submenu">
                <li className="submenu-item"><Link to="/inventory-dashboard">Inventory Dashboard</Link></li>
              </ul>
            )}
          </li>
          <li><Link to="/procurement"><span>Procurement</span></Link></li>
          <li><Link to="/shipping"><span>Shipping</span></Link></li>
          <li><Link to="/accounting"><span>Accounting</span></Link></li>
          <li><Link to="/data-imports"><span>Data Imports</span></Link></li>
          
          <li className="logout-item">
            <button onClick={handleLogout} className="logout-button">
              <span>Logout</span>
            </button>
          </li>
          
          <li className="presenter-mode-item">
            <button 
              onClick={() => setIsPresenterMode(!isPresenterMode)}
              className={`presenter-mode-button ${isPresenterMode ? 'active' : ''}`}
            >
              <span>{isPresenterMode ? '🔒 Exit Presenter Mode' : '🔓 Enter Presenter Mode'}</span>
            </button>
          </li>
        </ul>
      </div>
      
      <button 
        className="sidebar-collapse-btn" 
        onClick={() => onCollapse(!isCollapsed)}
      >
        {isCollapsed ? '›' : '‹'}
      </button>
    </nav>
  );
};

export default Sidebar;
