import { useEffect, useState, useRef } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeoutRef = useRef(null);
  const previousValueRef = useRef(value);

  useEffect(() => {
    // Check if the value has actually changed
    const hasChanged = Array.isArray(value) 
      ? value.some((v, i) => v?.getTime() !== previousValueRef.current?.[i]?.getTime())
      : value !== previousValueRef.current;

    if (!hasChanged) {
      return;
    }

    previousValueRef.current = value;

    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
} 