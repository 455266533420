import React, { useState, useEffect } from 'react';
import { getFinishedGoods } from '../api/finishedGoodsApi';
import FinishedGoodsTable from '../components/FinishedGoodsTable';
import './FinishedGoodsPage.css';
import ErrorBoundary from '../components/ErrorBoundary';

const FinishedGoodsPage = () => {
  const [finishedGoods, setFinishedGoods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFinishedGoods = async () => {
      try {
        const data = await getFinishedGoods();
        console.log('Fetched data:', data);
        setFinishedGoods(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching finished goods:', err);
        setError(`Failed to fetch finished goods data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchFinishedGoods();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="finished-goods-page">
      <h1>Finished Goods</h1>
      {finishedGoods.length > 0 ? (
        <ErrorBoundary>
          <FinishedGoodsTable data={finishedGoods} />
        </ErrorBoundary>
      ) : (
        <div>No finished goods data available</div>
      )}
    </div>
  );
};

export default FinishedGoodsPage;
