import React, { useState } from 'react';
import './SalesTable.css';
import AsinDetailModal from './AsinDetailModal';
import { Confidential } from './Confidential';

const formatCurrency = (value) => {
  const absValue = Math.abs(Math.round(value));
  return value < 0 ? `-$${absValue.toLocaleString()}` : `$${absValue.toLocaleString()}`;
};

const formatNumber = (value) => {
  return Math.round(value).toLocaleString();
};

const SalesTable = ({ data, columns, onSort, sortBy, sortOrder, currentDateRange }) => {
  const [selectedAsin, setSelectedAsin] = useState(null);

  const handleSort = (column) => {
    onSort(column);
  };

  const handleAsinClick = (asin) => {
    console.log('ASIN clicked:', asin);
    setSelectedAsin(asin);
  };

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  // Updated helper function to determine if a column contains confidential data
  const isConfidentialColumn = (column) => {
    const confidentialColumns = ['id', 'brand', 'sku'];  // Make sure these match your column names exactly
    return confidentialColumns.includes(column.toLowerCase());
  };

  const formatValue = (column, value) => {
    if (['revenue', 'net_profit'].includes(column)) {
      return formatCurrency(value);
    } else if (column === 'velocity') {
      return value.toFixed(2);
    } else if (['orders', 'units'].includes(column)) {
      return formatNumber(value);
    } else if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  };

  return (
    <div className="sales-table-container">
      <table className="sales-table">
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column} onClick={() => handleSort(column)}>
                {column}
                {sortBy === column && (sortOrder === 'asc' ? ' ▲' : ' ▼')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id || index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              {columns.map(column => (
                <td 
                  key={column} 
                  data-column={column}
                  className={column.toLowerCase() === 'id' ? 'clickable-asin' : ''}
                  onClick={() => column.toLowerCase() === 'id' ? handleAsinClick(item[column]) : null}
                >
                  {isConfidentialColumn(column) ? (
                    <Confidential>
                      {item[column] !== undefined ? formatValue(column, item[column]) : 'N/A'}
                    </Confidential>
                  ) : (
                    item[column] !== undefined ? formatValue(column, item[column]) : 'N/A'
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {selectedAsin && (
        <AsinDetailModal
          asin={selectedAsin}
          onClose={() => setSelectedAsin(null)}
          initialDateRange={currentDateRange}
        />
      )}
    </div>
  );
};

export default SalesTable;
