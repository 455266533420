import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFinishedGoodDetails } from '../api/finishedGoodsApi';
import './FinishedGoodDetailsPage.css'; // You'll need to create this CSS file

const FinishedGoodDetailsPage = () => {
  const { isku } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await getFinishedGoodDetails(isku);
        setDetails(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching finished good details:', error);
        setError('Failed to load finished good details');
        setLoading(false);
      }
    };
    fetchDetails();
  }, [isku]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!details) return <div className="no-data">No data available</div>;

  const formatCurrency = (value) => {
    return value !== null ? `$${parseFloat(value).toFixed(2)}` : 'N/A';
  };

  const renderDetailRow = (label, value, isCurrency = false) => (
    <div className="detail-row" key={label}>
      <span className="detail-label">{label}:</span>
      <span className="detail-value">
        {isCurrency ? formatCurrency(value) : (value !== null ? value.toString() : 'N/A')}
      </span>
    </div>
  );

  const leftColumnFields = ['isku', 'phoenix_class', 'brand', 'size', 'material_cost', 'labor_cost', 'total_unit_cost', 'replenishment_type', 'manufacturing_class'];
  const rightColumnFields = ['lead_time', 'amz_safety_days', 'wh_safety_days', 'reorder_qty_days', 'similar', 'in_bom_mapping', 'duplicate_asin', 'count_tkfg', 'batch_run', 'status'];

  return (
    <div className="finished-good-details">
      <h1>{details.finished_good.isku}</h1>
      <div className="details-section">
        <div className="details-column">
          {leftColumnFields.map(field => 
            renderDetailRow(field.replace(/_/g, ' ').toUpperCase(), details.finished_good[field], ['material_cost', 'labor_cost', 'total_unit_cost'].includes(field))
          )}
        </div>
        <div className="details-column">
          {rightColumnFields.map(field => 
            renderDetailRow(field.replace(/_/g, ' ').toUpperCase(), details.finished_good[field])
          )}
        </div>
      </div>
      <div className="components-section">
        <h2>BOMs</h2>
        {details.components && details.components.length > 0 ? (
          <table className="components-table">
            <thead>
              <tr>
                <th>BOM ID</th>
                <th>QUANTITY</th>
                <th>UOM</th>
                <th>COMPONENT TYPE</th>
                <th>MATERIAL UNIT PRICE</th>
                <th>PER FG PRICE</th>
                <th>BOM CLASS</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {details.components.map((component, index) => (
                <tr key={index}>
                  <td>{component.bom_id}</td>
                  <td>{component.quantity}</td>
                  <td>{component.uom}</td>
                  <td>{component.component_type}</td>
                  <td>{formatCurrency(component.material_unit_price)}</td>
                  <td>{formatCurrency(component.per_fg_price)}</td>
                  <td>{component.bom_class}</td>
                  <td>{component.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No BOMs found for this finished good.</p>
        )}
      </div>
    </div>
  );
};

export default FinishedGoodDetailsPage;
