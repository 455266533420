import React, { useState, useRef, useEffect } from 'react';

const TableViewSelector = ({ availableColumns, selectedColumns, onColumnChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleColumnToggle = (column) => {
    const updatedColumns = selectedColumns.includes(column)
      ? selectedColumns.filter(c => c !== column)
      : [...selectedColumns, column];
    onColumnChange(updatedColumns);
  };

  return (
    <div className="table-view-selector" ref={dropdownRef}>
      <button onClick={() => setIsOpen(!isOpen)}>Select Table View</button>
      {isOpen && (
        <div className="column-selector-popup">
          {availableColumns.map(column => (
            <label key={column}>
              <input
                type="checkbox"
                checked={selectedColumns.includes(column)}
                onChange={() => handleColumnToggle(column)}
              />
              {column}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableViewSelector;
