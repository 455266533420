import React, { useState, useEffect, useCallback } from 'react';
import DashboardDateRangePicker from '../components/DashboardDateRangePicker';
import DashboardSummaryCards from '../components/DashboardSummaryCards';
import DashboardFilters from '../components/DashboardFilters';
import DashboardGraph from '../components/DashboardGraph';
import { fetchSalesDashboard, fetchAvailableBrands, fetchAvailableTags } from '../api/salesApi';
import { formatDate } from '../utils/dateUtils';
import './SalesDashboardPage.css';

const SalesDashboardPage = () => {
  // State for date range and view
  const [viewBy, setViewBy] = useState('day');
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  
  // State for filters
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedAsin, setSelectedAsin] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  
  // State for data
  const [dashboardData, setDashboardData] = useState(null);
  const [hoveredData, setHoveredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for summary card date ranges
  const [summaryCardRanges, setSummaryCardRanges] = useState({
    card1: getDefaultDateRange('yesterday'),
    card2: getDefaultDateRange('7days'),
    card3: getDefaultDateRange('30days'),
    card4: getDefaultDateRange('ytd')
  });

  // Helper function to get default date ranges
  function getDefaultDateRange(preset) {
    const end = new Date();
    let start = new Date();

    switch (preset) {
      case 'yesterday':
        start.setDate(end.getDate() - 1);
        end.setDate(end.getDate() - 1);
        break;
      case '7days':
        start.setDate(end.getDate() - 7);
        break;
      case '30days':
        start.setDate(end.getDate() - 30);
        break;
      case 'ytd':
        start = new Date(end.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    return [start, end];
  }

  // Fetch available filters on mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [brands, tags] = await Promise.all([
          fetchAvailableBrands(),
          fetchAvailableTags()
        ]);
        setAvailableBrands(brands);
        setAvailableTags(tags);
      } catch (error) {
        console.error('Error fetching filters:', error);
        setError('Failed to load filter options');
      }
    };

    fetchFilters();
  }, []);

  // Fetch dashboard data
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const formattedSummaryRanges = {};
      Object.entries(summaryCardRanges).forEach(([key, [start, end]]) => {
        formattedSummaryRanges[key] = [
          formatDate(start),
          formatDate(end)
        ];
      });

      const data = await fetchSalesDashboard(
        viewBy,
        dateRange[0],
        dateRange[1],
        selectedBrands,
        selectedAsin,
        selectedTags,
        formattedSummaryRanges
      );
      setDashboardData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  }, [viewBy, dateRange, selectedBrands, selectedAsin, selectedTags, summaryCardRanges]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateRangeChange = useCallback((start, end) => {
    setDateRange([start, end]);
  }, []);

  const handleViewByChange = useCallback((newViewBy) => {
    setViewBy(newViewBy);
  }, []);

  const handleSummaryCardDateChange = useCallback((cardId, start, end) => {
    setSummaryCardRanges(prev => ({
      ...prev,
      [cardId]: [start, end]
    }));
  }, []);

  const handleHover = useCallback((data) => {
    setHoveredData(data);
  }, []);

  const formatDetailValue = (value, type) => {
    if (type === 'currency') {
      return `$${Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
    }
    if (type === 'percentage') {
      return `${value.toFixed(2)}%`;
    }
    return value.toLocaleString();
  };

  const formatDetailDate = (date) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);  // Add one day to match graph display

    switch (viewBy) {
      case 'year':
        return d.getFullYear().toString();
      case 'month':
        return d.toLocaleString('default', { month: 'long', year: 'numeric' });
      default:
        return formatDate(d);  // Use existing formatDate for daily view
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="sales-dashboard">
      <div className="dashboard-header">
        <h1>Sales Dashboard</h1>
        <DashboardDateRangePicker
          onDateRangeChange={handleDateRangeChange}
          onViewByChange={handleViewByChange}
          initialViewBy={viewBy}
        />
      </div>

      <DashboardFilters
        onBrandsChange={setSelectedBrands}
        onAsinChange={setSelectedAsin}
        onTagsChange={setSelectedTags}
        availableBrands={availableBrands}
        availableTags={availableTags}
      />

      <DashboardSummaryCards
        summaryData={dashboardData?.summary_cards || {}}
        onDateRangeChange={handleSummaryCardDateChange}
      />

      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <>
          <DashboardGraph
            data={dashboardData?.metrics || []}
            viewBy={viewBy}
            onHover={handleHover}
          />
          
          {hoveredData && (
            <div className="hover-details">
              <h3>Details for {formatDetailDate(hoveredData.date)}</h3>
              <div className="details-grid">
                <div className="detail-section">
                  <h4>Sales</h4>
                  <div className="detail-item">
                    <span>Ordered Product Sales:</span>
                    <span>{formatDetailValue(hoveredData.ordered_product_sales, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Net Profit:</span>
                    <span>{formatDetailValue(hoveredData.net_profit, 'currency')}</span>
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Costs</h4>
                  <div className="detail-item">
                    <span>PPC Product Cost:</span>
                    <span>{formatDetailValue(hoveredData.ppc_product_cost, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>PPC Video Cost:</span>
                    <span>{formatDetailValue(hoveredData.ppc_video_cost, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Promo Amount:</span>
                    <span>{formatDetailValue(hoveredData.promo_amount, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>COGS:</span>
                    <span>{formatDetailValue(hoveredData.cogs, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Commissions:</span>
                    <span>{formatDetailValue(hoveredData.commissions, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>FBA Fees:</span>
                    <span>{formatDetailValue(hoveredData.fba_fees, 'currency')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Remitting Tax:</span>
                    <span>{formatDetailValue(hoveredData.remitting_tax, 'currency')}</span>
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Orders Summary</h4>
                  <div className="detail-item">
                    <span>Orders:</span>
                    <span>{formatDetailValue(hoveredData.orders)}</span>
                  </div>
                  <div className="detail-item">
                    <span>Units:</span>
                    <span>{formatDetailValue(hoveredData.units)}</span>
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Performance Metrics</h4>
                  <div className="detail-item">
                    <span>TACoS:</span>
                    <span>{formatDetailValue(hoveredData.tacos, 'percentage')}</span>
                  </div>
                  <div className="detail-item">
                    <span>ACoS:</span>
                    <span>{formatDetailValue(hoveredData.acos, 'percentage')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Unit Session %:</span>
                    <span>{formatDetailValue(hoveredData.unit_session_percentage, 'percentage')}</span>
                  </div>
                  <div className="detail-item">
                    <span>Conversion Rate:</span>
                    <span>{formatDetailValue(hoveredData.conversion_rate, 'percentage')}</span>
                  </div>
                  <div className="detail-item">
                    <span>CTR:</span>
                    <span>{formatDetailValue(hoveredData.ctr, 'percentage')}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SalesDashboardPage; 