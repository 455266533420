import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DashboardDateRangePicker.css';

const DashboardDateRangePicker = ({ onDateRangeChange, onViewByChange, initialViewBy = 'day' }) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewBy, setViewBy] = useState(initialViewBy);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const handleViewByChange = (newViewBy) => {
    setViewBy(newViewBy);
    onViewByChange(newViewBy);
    
    // Reset date range based on view type
    const end = new Date();
    let start = new Date();
    
    switch (newViewBy) {
      case 'year':
        start.setFullYear(end.getFullYear() - 1);
        break;
      case 'month':
        start.setMonth(end.getMonth() - 11);
        break;
      case 'day':
        start.setDate(end.getDate() - 29);
        break;
      default:
        break;
    }
    
    setDateRange([start, end]);
    onDateRangeChange(start, end);
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    if (update[0] && update[1]) {
      onDateRangeChange(update[0], update[1]);
    }
  };

  const renderDatePicker = () => {
    switch (viewBy) {
      case 'year':
        return (
          <div className="year-picker">
            <div className="year-range">
              <select 
                value={startDate.getFullYear()} 
                onChange={(e) => {
                  const newStart = new Date(startDate);
                  newStart.setFullYear(e.target.value);
                  handleDateChange([newStart, endDate]);
                }}
              >
                {Array.from({ length: 5 }, (_, i) => 2020 + i).map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              <span>to</span>
              <select 
                value={endDate.getFullYear()}
                onChange={(e) => {
                  const newEnd = new Date(endDate);
                  newEnd.setFullYear(e.target.value);
                  handleDateChange([startDate, newEnd]);
                }}
              >
                {Array.from({ length: 5 }, (_, i) => 2020 + i).map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>
        );

      case 'month':
        return (
          <div className="month-picker">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateChange([date, endDate])}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              inline
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateChange([startDate, date])}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              inline
            />
          </div>
        );

      default: // day
        return (
          <div className="day-picker">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              inline
            />
            <div className="preset-options">
              <button onClick={() => handlePresetSelect('7days')}>Last 7 Days</button>
              <button onClick={() => handlePresetSelect('30days')}>Last 30 Days</button>
              <button onClick={() => handlePresetSelect('90days')}>Last 90 Days</button>
              <button onClick={() => handlePresetSelect('ytd')}>Year to Date</button>
            </div>
          </div>
        );
    }
  };

  const handlePresetSelect = (preset) => {
    const end = new Date();
    let start = new Date();

    switch (preset) {
      case '7days':
        end.setDate(end.getDate() - 1);  // End at yesterday
        start.setDate(end.getDate() - 6);  // Start 7 days before yesterday
        break;
      case '30days':
        end.setDate(end.getDate() - 1);  // End at yesterday
        start.setDate(end.getDate() - 29);  // Start 30 days before yesterday
        break;
      case '90days':
        end.setDate(end.getDate() - 1);  // End at yesterday
        start.setDate(end.getDate() - 89);  // Start 90 days before yesterday
        break;
      case 'ytd':
        end.setDate(end.getDate() - 1);  // End at yesterday
        start = new Date(end.getFullYear(), 0, 1);  // Start at January 1st
        break;
      default:
        return;
    }

    setDateRange([start, end]);
    onDateRangeChange(start, end);
    setIsModalOpen(false);
  };

  const formatDateRange = () => {
    if (!startDate || !endDate) return 'Select Date Range';

    switch (viewBy) {
      case 'year':
        return `${startDate.getFullYear()} - ${endDate.getFullYear()}`;
      case 'month':
        return `${startDate.toLocaleString('default', { month: 'short', year: 'numeric' })} - 
                ${endDate.toLocaleString('default', { month: 'short', year: 'numeric' })}`;
      default:
        return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    }
  };

  return (
    <div className="dashboard-date-range-picker">
      <div className="view-by-selector">
        <button 
          className={viewBy === 'day' ? 'active' : ''} 
          onClick={() => handleViewByChange('day')}
        >
          Daily
        </button>
        <button 
          className={viewBy === 'month' ? 'active' : ''} 
          onClick={() => handleViewByChange('month')}
        >
          Monthly
        </button>
        <button 
          className={viewBy === 'year' ? 'active' : ''} 
          onClick={() => handleViewByChange('year')}
        >
          Yearly
        </button>
      </div>
      
      <button 
        className="date-range-button" 
        onClick={() => setIsModalOpen(true)}
      >
        {formatDateRange()}
      </button>

      {isModalOpen && (
        <div className="date-range-modal">
          <div className="modal-content" ref={modalRef}>
            {renderDatePicker()}
            <div className="modal-actions">
              <button onClick={() => setIsModalOpen(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardDateRangePicker; 