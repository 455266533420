import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './DashboardFilters.css';

const DashboardFilters = ({ 
  onBrandsChange, 
  onAsinChange, 
  onTagsChange,
  availableBrands = [],
  availableTags = []
}) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [asin, setAsin] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const brandOptions = availableBrands.map(brand => ({
    value: brand,
    label: brand
  }));

  const tagOptions = availableTags.map(tag => ({
    value: tag,
    label: tag
  }));

  const handleBrandsChange = (selected) => {
    const brands = selected ? selected.map(option => option.value) : [];
    setSelectedBrands(selected || []);
    onBrandsChange(brands);
  };

  const handleAsinChange = (e) => {
    const value = e.target.value.trim().toUpperCase();
    setAsin(value);
    onAsinChange(value);
  };

  const handleTagsChange = (selected) => {
    const tags = selected ? selected.map(option => option.value) : [];
    setSelectedTags(selected || []);
    onTagsChange(tags);
  };

  const clearFilters = () => {
    setSelectedBrands([]);
    setAsin('');
    setSelectedTags([]);
    onBrandsChange([]);
    onAsinChange('');
    onTagsChange([]);
  };

  return (
    <div className="dashboard-filters">
      <div className="filter-group">
        <label>Brands</label>
        <Select
          isMulti
          value={selectedBrands}
          onChange={handleBrandsChange}
          options={brandOptions}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select brands..."
        />
      </div>

      <div className="filter-group">
        <label>ASIN</label>
        <input
          type="text"
          value={asin}
          onChange={handleAsinChange}
          placeholder="Enter ASIN..."
          className="asin-input"
        />
      </div>

      <div className="filter-group">
        <label>Tags</label>
        <Select
          isMulti
          value={selectedTags}
          onChange={handleTagsChange}
          options={tagOptions}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select tags..."
        />
      </div>

      <button 
        className="clear-filters-button"
        onClick={clearFilters}
      >
        Clear Filters
      </button>
    </div>
  );
};

export default DashboardFilters; 