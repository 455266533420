import React, { useState, useEffect, useRef } from 'react';
import './DashboardSummaryCards.css';

const PRESET_RANGES = {
  yesterday: {
    label: 'Yesterday',
    getDates: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return [yesterday, yesterday];
    }
  },
  last7days: {
    label: 'Last 7 Days',
    getDates: () => {
      const end = new Date();
      end.setDate(end.getDate() - 1);
      const start = new Date(end);
      start.setDate(end.getDate() - 6);
      return [start, end];
    }
  },
  last30days: {
    label: 'Last 30 Days',
    getDates: () => {
      const end = new Date();
      end.setDate(end.getDate() - 1);
      const start = new Date(end);
      start.setDate(end.getDate() - 29);
      return [start, end];
    }
  },
  ytd: {
    label: 'Year to Date',
    getDates: () => {
      const end = new Date();
      end.setDate(end.getDate() - 1);
      const start = new Date(end.getFullYear(), 0, 1);
      return [start, end];
    }
  }
};

const formatValue = (value, isMonetary = true) => {
  if (isMonetary) {
    return `$${Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  }
  return value.toLocaleString();
};

const formatDateRange = (start, end) => {
  if (start.getTime() === end.getTime()) {
    return new Date(start).toLocaleDateString();
  }
  return `${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}`;
};

const DashboardSummaryCard = ({ title, data, dateRange, onDateRangeChange, presetRanges }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handlePresetSelect = (preset) => {
    const [start, end] = presetRanges[preset].getDates();
    onDateRangeChange(start, end);
    setIsDropdownOpen(false);
  };

  return (
    <div className="dashboard-summary-card">
      <div className="card-header">
        <h3>{title}</h3>
        <div className="date-range-selector" ref={dropdownRef}>
          <button 
            className="date-range-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {dateRange}
          </button>
          {isDropdownOpen && (
            <div className="date-range-dropdown">
              {Object.entries(PRESET_RANGES).map(([key, { label }]) => (
                <button
                  key={key}
                  onClick={() => handlePresetSelect(key)}
                  className="preset-option"
                >
                  {label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="card-metrics">
        <div className="metric">
          <span className="metric-label">Ordered Product Sales</span>
          <span className="metric-value">{formatValue(data?.ordered_product_sales || 0)}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Revenue</span>
          <span className="metric-value">{formatValue(data?.revenue || 0)}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Net Profit</span>
          <span className="metric-value">{formatValue(data?.net_profit || 0)}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Orders</span>
          <span className="metric-value">{formatValue(data?.orders || 0, false)}</span>
        </div>
      </div>
    </div>
  );
};

const DashboardSummaryCards = ({ summaryData, onDateRangeChange }) => {
  const [dateRanges, setDateRanges] = useState({
    card1: formatDateRange(...PRESET_RANGES.yesterday.getDates()),
    card2: formatDateRange(...PRESET_RANGES.last7days.getDates()),
    card3: formatDateRange(...PRESET_RANGES.last30days.getDates()),
    card4: formatDateRange(...PRESET_RANGES.ytd.getDates())
  });

  const handleDateRangeChange = (cardId, start, end) => {
    setDateRanges(prev => ({
      ...prev,
      [cardId]: formatDateRange(start, end)
    }));
    onDateRangeChange(cardId, start, end);
  };

  return (
    <div className="dashboard-summary-cards">
      <DashboardSummaryCard
        title="Yesterday"
        data={summaryData.card1}
        dateRange={dateRanges.card1}
        onDateRangeChange={(start, end) => handleDateRangeChange('card1', start, end)}
        presetRanges={PRESET_RANGES}
      />
      <DashboardSummaryCard
        title="Last 7 Days"
        data={summaryData.card2}
        dateRange={dateRanges.card2}
        onDateRangeChange={(start, end) => handleDateRangeChange('card2', start, end)}
        presetRanges={PRESET_RANGES}
      />
      <DashboardSummaryCard
        title="Last 30 Days"
        data={summaryData.card3}
        dateRange={dateRanges.card3}
        onDateRangeChange={(start, end) => handleDateRangeChange('card3', start, end)}
        presetRanges={PRESET_RANGES}
      />
      <DashboardSummaryCard
        title="Year to Date"
        data={summaryData.card4}
        dateRange={dateRanges.card4}
        onDateRangeChange={(start, end) => handleDateRangeChange('card4', start, end)}
        presetRanges={PRESET_RANGES}
      />
    </div>
  );
};

export default DashboardSummaryCards; 