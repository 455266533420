import api from './axiosConfig';

export const createManufacturingPlan = async (planData) => {
  try {
    const response = await api.post('/manufacturing-plans/', planData);
    return response.data;
  } catch (error) {
    console.error('Error creating manufacturing plan:', error);
    throw error;
  }
};

export const getCurrentWeekPlans = async () => {
  try {
    const response = await api.get('/manufacturing-plans/current-week');
    return response.data;
  } catch (error) {
    console.error('Error fetching current week plans:', error);
    throw error;
  }
};

export const getManufacturingPlan = async (planId) => {
  try {
    const response = await api.get(`/manufacturing-plans/${planId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manufacturing plan:', error);
    throw error;
  }
};

export const updatePlanItem = async (planId, itemId, updateData) => {
  try {
    const response = await api.put(
      `/manufacturing-plans/${planId}/items/${itemId}`,
      updateData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating plan item:', error);
    throw error;
  }
};

export const recalculatePlan = async (planId) => {
  try {
    const response = await api.post(`/manufacturing-plans/${planId}/recalculate`);
    return response.data;
  } catch (error) {
    console.error('Error recalculating plan:', error);
    throw error;
  }
};

export const updateManufacturingPlan = async (planId, updateData) => {
  try {
    const response = await api.put(`/manufacturing-plans/${planId}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating manufacturing plan:', error);
    throw error;
  }
}; 