import api from './axiosConfig';
import { formatDate } from '../utils/dateUtils';

export const fetchSalesData = async (
  startDate,
  endDate,
  page = 1,
  sortBy = 'net_profit',
  sortOrder = 'desc',
  accountTitles = [],
  search = '',
  groupBy = 'asin'
) => {
  try {
    console.log('salesApi: Fetching sales data with params:', { startDate, endDate, page, sortBy, sortOrder, accountTitles, search, groupBy });
    const response = await api.get('/sales-per-day-per-product', {
      params: {
        start_date: startDate,
        end_date: endDate,
        page,
        sort_by: sortBy,
        sort_order: sortOrder,
        account_titles: accountTitles.join(','),
        search,
        group_by: groupBy,
      },
    });
    console.log('salesApi: Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('salesApi: Error fetching sales data:', error.response ? error.response.data : error.message);
    throw new Error(error.response?.data?.detail || 'An unexpected error occurred while fetching sales data');
  }
};

export const fetchSalesColumns = async (groupBy = 'asin') => {
  try {
    const columns = [
      'id', 'accounts', 'sku', 'fnsku', 'brand', 'orders', 'units', 'canceled_orders',
      'shipped', 'refunded', 'promo_units', 'non_promo_units', 'non_promo_revenue',
      'sales', 'revenue', 'velocity', 'fba_commissions', 'commissions', 'cogs', 'net_profit', 'ppc_orders',
      'ppc_impressions', 'ppc_clicks', 'ppc_sales', 'ppc_cost', 'tacos',
      'ppc_conversions', 'page_views', 'sessions', 'unit_session_percentage',
      'ppc_product_sales', 'ppc_product_cost', 'ppc_product_clicks',
      'ppc_product_impressions', 'ppc_video_sales', 'ppc_video_cost',
      'ppc_video_clicks', 'ppc_video_impressions'
    ];
    return columns;
  } catch (error) {
    console.error('Error fetching sales columns:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchAccountTitles = async () => {
  try {
    const response = await api.get('/account-titles');
    return response.data;
  } catch (error) {
    console.error('Error fetching account titles:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchAsinDetails = async (asin, startDate, endDate, signal) => {
  try {
    console.log('salesApi: Fetching ASIN details:', { asin, startDate, endDate });
    
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
      throw new Error('Invalid date format');
    }

    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${month}/${day}/${year}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    const response = await api.get(`/asin-details/${asin}`, {
      params: {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      },
      signal
    });
    
    console.log('salesApi: Response:', response.data);
    return response.data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Request was cancelled');
      throw error;
    }
    console.error('salesApi: Error:', error);
    throw error;
  }
};

export const fetchSalesDashboard = async (
  viewBy,
  startDate,
  endDate,
  brands = [],
  asin = '',
  tags = [],
  summaryRanges = {}
) => {
  try {
    // Format the summary ranges
    const formattedSummaryRanges = {};
    Object.entries(summaryRanges).forEach(([key, [start, end]]) => {
      formattedSummaryRanges[key] = [
        formatDate(start),
        formatDate(end)
      ];
    });

    console.log('Fetching dashboard with params:', {
      view_by: viewBy,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      brands: brands.length ? brands.join(',') : undefined,
      asin: asin || undefined,
      tags: tags.length ? tags.join(',') : undefined,
      summary_ranges: JSON.stringify(formattedSummaryRanges)
    });

    const response = await api.get('/sales-dashboard', {
      params: {
        view_by: viewBy,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        brands: brands.length ? brands.join(',') : undefined,
        asin: asin || undefined,
        tags: tags.length ? tags.join(',') : undefined,
        summary_ranges: JSON.stringify(formattedSummaryRanges)
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sales dashboard:', error.response?.data || error);
    throw new Error(error.response?.data?.detail || 'Failed to fetch dashboard data');
  }
};

export const fetchAvailableBrands = async () => {
  try {
    const response = await api.get('/sales-dashboard/brands');
    return response.data;
  } catch (error) {
    console.error('Error fetching brands:', error);
    throw error;
  }
};

export const fetchAvailableTags = async () => {
  try {
    const response = await api.get('/sales-dashboard/tags');
    return response.data;
  } catch (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }
};
