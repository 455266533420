import axios from 'axios';

const isProd = process.env.NODE_ENV === 'production';
const API_URL = isProd 
  ? 'https://backend-367414107699.us-central1.run.app/api/v1'
  : 'http://localhost:8000/api/v1';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Add response interceptor for error handling
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error.response || error);
    return Promise.reject(error);
  }
);

export default api;
