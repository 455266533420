import React, { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email.endsWith('@novaeo.com')) {
        setIsLoggedIn(true);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      if (user.email.endsWith('@novaeo.com')) {
        navigate('/');
      } else {
        await auth.signOut();
        setError("Only @novaeo.com accounts are allowed.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-page flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 text-gray-100 relative overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10px] opacity-50">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1000px] h-[1000px] bg-blue-500 rounded-full blur-[120px] animate-pulse"></div>
          <div className="absolute top-1/4 left-1/4 w-[800px] h-[800px] bg-purple-500 rounded-full blur-[120px] animate-pulse delay-700"></div>
          <div className="absolute bottom-1/4 right-1/4 w-[900px] h-[900px] bg-indigo-500 rounded-full blur-[120px] animate-pulse delay-1000"></div>
        </div>
      </div>

      {/* Main content */}
      <div className="w-full max-w-3xl p-16 space-y-12 bg-gray-800/40 backdrop-blur-xl rounded-3xl shadow-2xl border border-gray-700/30 relative z-10 transform hover:scale-[1.02] transition-all duration-300">
        <div className="space-y-8">
          <div className="text-center space-y-6">
            <h1 className="text-9xl font-black text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-400 to-indigo-400 animate-gradient pb-4 tracking-tight leading-none">
              THE NOVAEO APP
            </h1>
            <p className="text-center text-gray-400 text-3xl font-light tracking-wide">
              Supply Chain Management System
            </p>
          </div>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
            <p className="text-red-400 text-center">{error}</p>
          </div>
        )}

        {isLoggedIn ? (
          <div className="text-center space-y-6">
            <p className="text-gray-300 text-xl">You are already logged in</p>
            <button
              onClick={() => navigate('/')}
              className="w-full py-5 px-8 text-white bg-gradient-to-r from-blue-600 to-indigo-600 rounded-xl hover:from-blue-500 hover:to-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl font-semibold text-xl"
            >
              Go to Dashboard
            </button>
            <button
              onClick={() => auth.signOut()}
              className="w-full py-5 px-8 text-white bg-gradient-to-r from-red-600 to-pink-600 rounded-xl hover:from-red-500 hover:to-pink-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl font-semibold text-xl"
            >
              Sign Out
            </button>
          </div>
        ) : (
          <button
            onClick={handleGoogleSignIn}
            className="w-full py-6 px-8 text-white text-xl bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 rounded-xl hover:from-blue-500 hover:via-indigo-500 hover:to-purple-500 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-2xl font-semibold group relative overflow-hidden"
          >
            <span className="absolute inset-0 bg-gradient-to-r from-blue-400/20 via-transparent to-transparent group-hover:translate-x-full transition-transform duration-700"></span>
            Sign in with Google
          </button>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
