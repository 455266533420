import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import Sidebar from './components/Sidebar';
import LoginPage from './pages/LoginPage';
import ProcurementPage from './pages/ProcurementPage';
import Dashboard from './pages/Dashboard';
import FinishedGoodsPage from './pages/FinishedGoodsPage';
import FinishedGoodDetailsPage from './pages/FinishedGoodDetailsPage';
import BOMListPage from './pages/BOMListPage';
import DataImportsPage from './pages/DataImportsPage';
import InventoryDashboardPage from './pages/InventoryDashboardPage';
import SalesDashboardPage from './pages/SalesDashboardPage';
import './App.css';
import './styles/presenterMode.css';
import { PresenterModeProvider } from './context/PresenterModeContext';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email.endsWith('@novaeo.com')) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <div className="text-xl text-gray-100">Loading...</div>
      </div>
    );
  }

  return (
    <PresenterModeProvider>
      <Router>
        <div className="app-wrapper">
          {user && window.location.pathname !== '/login' && (
            <Sidebar onCollapse={setIsSidebarCollapsed} isCollapsed={isSidebarCollapsed} />
          )}
          <div 
            className={`content-wrapper ${
              window.location.pathname === '/login' ? 'login-page' : 
              isSidebarCollapsed ? 'sidebar-collapsed' : ''
            }`}
          >
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/procurement" element={user ? <ProcurementPage /> : <Navigate to="/login" />} />
              <Route path="/sales-report" element={user ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/sales-dashboard" element={user ? <SalesDashboardPage /> : <Navigate to="/login" />} />
              <Route path="/isku-list" element={user ? <FinishedGoodsPage /> : <Navigate to="/login" />} />
              <Route path="/finished-goods/:isku" element={user ? <FinishedGoodDetailsPage /> : <Navigate to="/login" />} />
              <Route path="/bom-list" element={user ? <BOMListPage /> : <Navigate to="/login" />} />
              <Route path="/data-imports" element={user ? <DataImportsPage /> : <Navigate to="/login" />} />
              <Route path="/inventory-dashboard" element={user ? <InventoryDashboardPage /> : <Navigate to="/login" />} />
            </Routes>
          </div>
        </div>
      </Router>
    </PresenterModeProvider>
  );
}

export default App;
