import React, { useState, useCallback } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid
} from 'recharts';
import './DashboardGraph.css';

const DashboardGraph = ({ data, viewBy, onHover }) => {
  const [visibleMetrics, setVisibleMetrics] = useState({
    orders: true,
    units: true,
    tacos: true,
    acos: true,
    unit_session_percentage: true,
    conversion_rate: true,
    ctr: true
  });

  const formatDate = (date) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);

    switch (viewBy) {
      case 'year':
        return d.getFullYear();
      case 'month':
        return d.toLocaleString('default', { month: 'short', year: 'numeric' });
      default:
        return d.toLocaleDateString();
    }
  };

  const toggleMetric = (metric) => {
    setVisibleMetrics(prev => ({
      ...prev,
      [metric]: !prev[metric]
    }));
  };

  const handleMouseMove = useCallback((e) => {
    if (e.activePayload) {
      onHover(e.activePayload[0].payload);
    }
  }, [onHover]);

  const lineMetrics = [
    { key: 'orders', color: '#059669', name: 'Orders' },
    { key: 'units', color: '#0EA5E9', name: 'Units' },
    { key: 'tacos', color: '#FACC15', name: 'TACoS' },
    { key: 'acos', color: '#EC4899', name: 'ACoS' },
    { key: 'unit_session_percentage', color: '#F59E0B', name: 'Unit Session %' },
    { key: 'conversion_rate', color: '#10B981', name: 'Conversion Rate' },
    { key: 'ctr', color: '#6366F1', name: 'CTR' }
  ];

  const transformedData = data.map(item => ({
    ...item,
    date: item.date,
    ordered_product_sales: item.ordered_product_sales,
    net_profit: item.net_profit,
    ppc_product_cost: Math.abs(item.ppc_product_cost || 0),
    ppc_video_cost: Math.abs(item.ppc_video_cost || 0),
    promo_amount: Math.abs(item.promo_amount || 0),
    cogs: Math.abs(item.cogs || 0),
    commissions: Math.abs(item.commissions || 0),
    fba_fees: Math.abs(item.fba_fees || 0),
    remitting_tax: Math.abs(item.remitting_tax || 0),
    orders: item.orders,
    units: item.units,
    tacos: item.tacos,
    acos: item.acos,
    unit_session_percentage: item.unit_session_percentage,
    conversion_rate: item.conversion_rate,
    ctr: item.ctr
  }));

  return (
    <div className="dashboard-graph">
      <div className="metric-toggles">
        {lineMetrics.map(({ key, name, color }) => (
          <label key={key} className="metric-toggle">
            <input
              type="checkbox"
              checked={visibleMetrics[key]}
              onChange={() => toggleMetric(key)}
            />
            <span style={{ backgroundColor: color }} className="color-dot"></span>
            {name}
          </label>
        ))}
      </div>

      <div className="graph-container">
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            data={transformedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            onMouseMove={handleMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis 
              dataKey="date" 
              tickFormatter={formatDate}
              stroke="#9CA3AF"
            />

            {/* Single Y-axis for sales and costs */}
            <YAxis
              yAxisId="sales"
              orientation="left"
              stroke="#10B981"
              tickFormatter={(value) => `$${Math.abs(value).toLocaleString()}`}
            />

            {/* Metrics and percentages Y-axes */}
            <YAxis
              yAxisId="metrics"
              orientation="right"
              stroke="#9CA3AF"
              tickFormatter={(value) => `${value.toFixed(0)}`}
            />

            <YAxis
              yAxisId="percentages"
              orientation="right"
              stroke="#9CA3AF"
              tickFormatter={(value) => `${value.toFixed(1)}%`}
              offset={60}
            />

            <YAxis
              yAxisId="ctr"
              orientation="right"
              stroke="#6366F1"
              tickFormatter={(value) => `${value.toFixed(2)}%`}
              offset={120}
              domain={[0, 'auto']}
              allowDataOverflow={false}
              scale="linear"
            />

            <Legend />

            {/* Ordered Product Sales Bar */}
            <Bar
              dataKey="ordered_product_sales"
              name="Ordered Product Sales"
              fill="#3B82F6"
              yAxisId="sales"
              barSize={80}
            />

            {/* Cost Stack - now using same yAxisId as sales */}
            <Bar
              dataKey="ppc_product_cost"
              name="PPC Product Cost"
              fill="#EF4444"
              yAxisId="sales"
              stackId="costs"
              barSize={80}
            />
            <Bar
              dataKey="ppc_video_cost"
              name="PPC Video Cost"
              fill="#7C3AED"
              yAxisId="sales"
              stackId="costs"
            />
            <Bar
              dataKey="promo_amount"
              name="Promo Amount"
              fill="#9333EA"
              yAxisId="sales"
              stackId="costs"
            />
            <Bar
              dataKey="cogs"
              name="COGS"
              fill="#C2410C"
              yAxisId="sales"
              stackId="costs"
            />
            <Bar
              dataKey="commissions"
              name="Commissions"
              fill="#EC4899"
              yAxisId="sales"
              stackId="costs"
            />
            <Bar
              dataKey="fba_fees"
              name="FBA Fees"
              fill="#10B981"
              yAxisId="sales"
              stackId="costs"
            />
            <Bar
              dataKey="remitting_tax"
              name="Remitting Tax"
              fill="#F97316"
              yAxisId="sales"
              stackId="costs"
            />

            {/* Line Metrics */}
            {visibleMetrics.orders && (
              <Line
                type="monotone"
                dataKey="orders"
                name="Orders"
                stroke="#059669"
                yAxisId="metrics"
                dot={false}
              />
            )}
            {visibleMetrics.units && (
              <Line
                type="monotone"
                dataKey="units"
                name="Units"
                stroke="#0EA5E9"
                yAxisId="metrics"
                dot={false}
              />
            )}

            {/* Percentage Lines */}
            {visibleMetrics.tacos && (
              <Line
                type="monotone"
                dataKey="tacos"
                name="TACoS"
                stroke="#FACC15"
                yAxisId="percentages"
                dot={false}
              />
            )}
            {visibleMetrics.acos && (
              <Line
                type="monotone"
                dataKey="acos"
                name="ACoS"
                stroke="#EC4899"
                yAxisId="percentages"
                dot={false}
              />
            )}
            {visibleMetrics.unit_session_percentage && (
              <Line
                type="monotone"
                dataKey="unit_session_percentage"
                name="Unit Session %"
                stroke="#F59E0B"
                yAxisId="percentages"
                dot={false}
              />
            )}
            {visibleMetrics.conversion_rate && (
              <Line
                type="monotone"
                dataKey="conversion_rate"
                name="Conversion Rate"
                stroke="#10B981"
                yAxisId="percentages"
                dot={false}
              />
            )}
            {visibleMetrics.ctr && (
              <Line
                type="monotone"
                dataKey="ctr"
                name="CTR"
                stroke="#6366F1"
                yAxisId="ctr"
                dot={false}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DashboardGraph; 