import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ message }) => {
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      <div className="loading-text">{message || 'Loading...'}</div>
    </div>
  );
};

export default LoadingSpinner; 