import api from './axiosConfig';

export const getFinishedGoods = async (phoenixClass, status) => {
  try {
    const params = {};
    if (phoenixClass) {
      if (phoenixClass === 'ALL') {
        params.phoenix_class = 'ALL';
      } else if (phoenixClass === 'None') {
        params.phoenix_class = 'None';
      } else {
        params.phoenix_class = phoenixClass;
      }
    }
    if (status) params.status = status;

    console.log('Fetching finished goods with params:', params);
    const response = await api.get('/finished-goods', { params });
    console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching finished goods:', error);
    throw error;
  }
};

export const getFinishedGoodDetails = async (isku) => {
  try {
    const response = await api.get(`/finished-goods/${isku}`);
    console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching finished good details:', error);
    throw error;
  }
};

export const getFinishedGoodsPhoenixClasses = async () => {
  try {
    const response = await api.get('/finished-goods/phoenix-classes');
    return response.data;
  } catch (error) {
    console.error('Error fetching phoenix classes:', error);
    throw error;
  }
};

export const getFinishedGoodsStatuses = async () => {
  try {
    const response = await api.get('/finished-goods/statuses');
    return response.data;
  } catch (error) {
    console.error('Error fetching statuses:', error);
    throw error;
  }
};
