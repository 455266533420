import React, { createContext, useContext, useState, useEffect } from 'react';

const PresenterModeContext = createContext();

export function PresenterModeProvider({ children }) {
  const [isPresenterMode, setIsPresenterMode] = useState(() => {
    // Check localStorage for saved preference
    return JSON.parse(localStorage.getItem('presenterMode')) || false;
  });

  // Update localStorage when presenter mode changes
  useEffect(() => {
    localStorage.setItem('presenterMode', JSON.stringify(isPresenterMode));
  }, [isPresenterMode]);

  return (
    <PresenterModeContext.Provider value={{ isPresenterMode, setIsPresenterMode }}>
      {children}
    </PresenterModeContext.Provider>
  );
}

export function usePresenterMode() {
  return useContext(PresenterModeContext);
} 