import React from 'react';
import { usePresenterMode } from '../context/PresenterModeContext';

export const Confidential = ({ children, type }) => {
  const { isPresenterMode } = usePresenterMode();
  
  return (
    <span 
      className={`confidential-wrapper ${isPresenterMode ? 'blur-confidential' : ''}`}
      style={isPresenterMode ? {
        filter: 'blur(4px)',
        display: 'inline-block',
        userSelect: 'none'
      } : {}}
    >
      {children}
    </span>
  );
}; 