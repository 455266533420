import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyD0sqC2DfoSwEc2ru6CSVa7koSUzV7ltoY',
  authDomain: 'novaeo-ims.firebaseapp.com',
  projectId: 'novaeo-ims',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
