import api from './axiosConfig';

export const runImport = async (importName) => {
  try {
    console.log(`Attempting to run import for: ${importName}`);
    const response = await api.post(`run`, { import_name: importName });
    console.log('Import response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in runImport:', error.response || error);
    throw error;
  }
};

export const getLastImportDate = async (importName) => {
  try {
    console.log(`Attempting to get last import date for: ${importName}`);
    const response = await api.get(`import-status/${encodeURIComponent(importName)}`);
    console.log('Last import date response:', response.data);
    return response.data.last_run ? new Date(response.data.last_run).toLocaleString() : 'Never';
  } catch (error) {
    console.error('Error in getLastImportDate:', error.response || error);
    return 'Never';
  }
};

export const getImportStatus = async (importName) => {
  try {
    console.log(`Getting status for import: ${importName}`);
    const response = await api.get(`import-status/${encodeURIComponent(importName)}`);
    console.log('Import status response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching import status:', error);
    throw error;
  }
};
