import React, { useState, useEffect } from 'react';
import { getBOMs } from '../api/bomApi';
import BOMTable from '../components/BOMTable';
import './BOMListPage.css';

const BOMListPage = () => {
  const [boms, setBOMs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchBOMs = async () => {
    try {
      setLoading(true);
      const data = await getBOMs(page);
      if (Array.isArray(data) && data.length > 0) {
        setBOMs(prevBOMs => [...prevBOMs, ...data]);
        setHasMore(data.length === 100); // Assuming 100 is the limit per page
      } else {
        setHasMore(false);
        if (page === 1) {
          console.error('Received invalid data format:', data);
          setError('Received invalid data format from the server');
        }
      }
    } catch (err) {
      console.error('Error fetching BOMs:', err);
      setError(`Failed to fetch BOM data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBOMs();
  }, [page]);

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bom-list-page">
      {boms.length > 0 ? (
        <>
          <BOMTable data={boms} />
          {hasMore && (
            <button onClick={loadMore} disabled={loading}>
              {loading ? 'Loading...' : 'Load More'}
            </button>
          )}
        </>
      ) : (
        loading ? <div>Loading...</div> : <div>No BOM data available</div>
      )}
    </div>
  );
};

export default BOMListPage;
