import api from './axiosConfig';

export const fetchInventoryDashboard = async (page, itemsPerPage, status, columns, sortBy, searchQuery, signal) => {
  try {
    const response = await api.get('/inventory-dashboard', {
      params: {
        page,
        items_per_page: itemsPerPage,
        status: status || undefined,
        columns: columns.join(','),
        sort_by: sortBy.map(sort => `${sort.id},${sort.desc ? 'desc' : 'asc'}`).join(';'),
        search: searchQuery || undefined,
      },
      signal,
    });
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching inventory dashboard:', error);
      throw error;
    }
  }
};

export const fetchInventoryDashboardColumns = async (signal) => {
  try {
    const response = await api.get('/inventory-dashboard/columns', { signal });
    return response.data;
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching inventory dashboard columns:', error);
      throw error;
    }
  }
};

export const fetchInventoryDashboardStatuses = async (signal) => {
  try {
    const response = await api.get('/inventory-dashboard/statuses', { signal });
    return response.data;
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error fetching inventory dashboard statuses:', error);
      throw error;
    }
  }
};
