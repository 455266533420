import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
  createManufacturingPlan, 
  updatePlanItem, 
  recalculatePlan,
  getCurrentWeekPlans,
  getManufacturingPlan 
} from '../api/manufacturingPlanApi';
import './ManufacturingPlan.css';
import ManufacturingPlanErrorBoundary from './ManufacturingPlanErrorBoundary';
import LoadingSpinner from './LoadingSpinner';

const ManufacturingPlan = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxUnitsPerWeek, setMaxUnitsPerWeek] = useState(1000);
  const [numberOfWeeks, setNumberOfWeeks] = useState(4);
  const [includeZeroVelocity, setIncludeZeroVelocity] = useState(false);
  const [planName, setPlanName] = useState('');
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedBOMs, setExpandedBOMs] = useState({});
  const [ignoreBOMInventory, setIgnoreBOMInventory] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);

  // Load saved plan ID from localStorage on mount
  useEffect(() => {
    const savedPlanId = localStorage.getItem('currentPlanId');
    if (savedPlanId) {
      setCurrentPlanId(parseInt(savedPlanId));
    }
  }, []);

  // Load plan data when currentPlanId changes
  useEffect(() => {
    const loadPlan = async () => {
      if (!currentPlanId) return;
      
      try {
        setLoading(true);
        setError(null);
        const planData = await getManufacturingPlan(currentPlanId);
        setPlan(planData);
        setIsExpanded(true);
        setPlanName(planData.name);
        setMaxUnitsPerWeek(planData.max_units_per_week);
        setNumberOfWeeks(planData.number_of_weeks);
        setIncludeZeroVelocity(planData.include_zero_velocity);
        setIgnoreBOMInventory(planData.ignore_bom_inventory);
      } catch (err) {
        console.error('Failed to load plan:', err);
        setError('Failed to load the manufacturing plan');
        // Clear invalid plan ID
        localStorage.removeItem('currentPlanId');
        setCurrentPlanId(null);
      } finally {
        setLoading(false);
      }
    };

    if (isExpanded) {
      loadPlan();
    }
  }, [currentPlanId, isExpanded]);

  // Load most recent plan if no current plan
  useEffect(() => {
    const loadMostRecentPlan = async () => {
      if (currentPlanId || !isExpanded) return;

      try {
        setLoading(true);
        setError(null);
        const plans = await getCurrentWeekPlans();
        
        const mostRecent = plans.reduce((latest, current) => {
          if (!latest || new Date(current.created_at) > new Date(latest.created_at)) {
            return current;
          }
          return latest;
        }, null);
        
        if (mostRecent) {
          setPlan(mostRecent);
          setCurrentPlanId(mostRecent.id);
          localStorage.setItem('currentPlanId', mostRecent.id);
          setPlanName(mostRecent.name);
          setMaxUnitsPerWeek(mostRecent.max_units_per_week);
          setNumberOfWeeks(mostRecent.number_of_weeks);
          setIncludeZeroVelocity(mostRecent.include_zero_velocity);
          setIgnoreBOMInventory(mostRecent.ignore_bom_inventory);
        }
      } catch (err) {
        console.error('Failed to load recent plan:', err);
        setError('Failed to load the most recent manufacturing plan');
      } finally {
        setLoading(false);
      }
    };

    loadMostRecentPlan();
  }, [currentPlanId, isExpanded]);

  const generatePlan = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const planData = {
        name: planName,
        max_units_per_week: maxUnitsPerWeek,
        number_of_weeks: numberOfWeeks,
        include_zero_velocity: includeZeroVelocity,
        ignore_bom_inventory: ignoreBOMInventory,
        week_starting: new Date().toISOString(),
        created_by: 'current_user',
      };

      const newPlan = await createManufacturingPlan(planData);
      
      // Fetch the complete plan data after creation
      const completePlan = await getManufacturingPlan(newPlan.id);
      setPlan(completePlan);
      setCurrentPlanId(newPlan.id);
      localStorage.setItem('currentPlanId', newPlan.id);
    } catch (err) {
      console.error('Failed to generate plan:', err);
      setError(err.response?.data?.detail || 'Failed to generate manufacturing plan');
    } finally {
      setLoading(false);
    }
  }, [planName, maxUnitsPerWeek, numberOfWeeks, includeZeroVelocity, ignoreBOMInventory]);

  const handleDragEnd = useCallback(async (result) => {
    if (!result.destination || !plan) return;

    const { source, destination, draggableId } = result;
    
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Update the item's week
      await updatePlanItem(plan.id, draggableId, {
        week_number: parseInt(destination.droppableId),
        moved_for_batch_run: false,
      });

      // Recalculate the plan
      const updatedPlan = await recalculatePlan(plan.id);
      setPlan(updatedPlan);
    } catch (err) {
      console.error('Failed to update plan:', err);
      setError(err.response?.data?.detail || 'Failed to update manufacturing plan');
    } finally {
      setLoading(false);
    }
  }, [plan]);

  const toggleBOMDetails = useCallback((itemId) => {
    setExpandedBOMs(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  }, []);

  const renderBOMDetails = useCallback((item) => {
    if (!expandedBOMs[item.id]) return null;

    console.log('Item:', item);
    console.log('BOM allocations:', item.bom_allocations);

    if (!item.bom_allocations || item.bom_allocations.length === 0) {
      return (
        <div className="bom-details">
          <div className="no-bom-message">No BOM details available</div>
        </div>
      );
    }

    return (
      <div className="bom-details">
        <div className="bom-header">
          <div>BOM ID</div>
          <div>Needed</div>
          <div>Available</div>
          <div>UOM</div>
          <div>Open POs</div>
          <div>PO Qty</div>
          <div>PO Expected Delivery Date</div>
        </div>
        {item.bom_allocations.map(bom => (
          <div key={bom.id} className="bom-row">
            <div>{bom.bom_id}</div>
            <div>{bom.quantity_needed.toFixed(2)}</div>
            <div className={bom.quantity_available < bom.quantity_needed ? 'quantity-warning' : ''}>
              {bom.quantity_available.toFixed(2)}
            </div>
            <div>{bom.uom}</div>
            <div className="po-details">{bom.po_numbers || 'None'}</div>
            <div className="po-details">{bom.po_qtys || 'N/A'}</div>
            <div className="po-details">{bom.po_dates || 'N/A'}</div>
          </div>
        ))}
      </div>
    );
  }, [expandedBOMs]);

  const renderWeek = useCallback((weekNumber, items) => {
    if (!items || items.length === 0) {
      return (
        <Droppable droppableId={weekNumber.toString()} key={weekNumber}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`week-container ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
            >
              <div className="week-header">
                <h3>Week {weekNumber}</h3>
                <div className="week-total">Total Units: 0</div>
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }

    const weekTotal = items.reduce((sum, item) => sum + item.adjusted_quantity, 0);
    
    return (
      <Droppable droppableId={weekNumber.toString()} key={weekNumber}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`week-container ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
          >
            <div className="week-header">
              <h3>Week {weekNumber}</h3>
              <div className="week-total">
                Total Units: {weekTotal.toLocaleString()}
              </div>
            </div>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`plan-item ${snapshot.isDragging ? 'dragging' : ''} ${
                      item.moved_for_batch_run ? 'batch-moved' : ''
                    }`}
                  >
                    <div className="item-header">
                      <div className="item-identifiers">
                        <span className="isku">{item.isku}</span>
                        <span className="asin">{item.asin}</span>
                      </div>
                      <button 
                        onClick={() => toggleBOMDetails(item.id)}
                        className="bom-toggle"
                      >
                        {expandedBOMs[item.id] ? '▼' : '▶'}
                      </button>
                    </div>
                    <div className="item-details">
                      <div className="quantity">
                        Quantity: {item.adjusted_quantity.toLocaleString()}
                      </div>
                      <div className="fba-days-container">
                        <div className="fba-days-item">
                          <span>Total FBA Days Before: {item.total_fba_days_before?.toFixed(1) || 'N/A'}</span>
                        </div>
                        <div className="fba-days-item">
                          <span>Total FBA Days After: {item.total_fba_days_after?.toFixed(1) || 'N/A'}</span>
                        </div>
                        <div className="fba-days-item">
                          <span>Final Velocity: {item.final_velocity?.toFixed(1) || 'N/A'}</span>
                        </div>
                        <div className="fba-days-item">
                          <span>
                            Restock Quantity = (Reorder Point Days ({item.reorder_point_days || 'N/A'}) - 
                            Total FBA Days Before ({item.total_fba_days_before?.toFixed(1) || 'N/A'}) + 
                            ReOrder Quantity ({item.reorder_qty_days || 'N/A'}))*
                            Final Velocity({item.final_velocity?.toFixed(1) || 'N/A'}) = {
                              Math.round((item.reorder_point_days - item.total_fba_days_before + item.reorder_qty_days) * item.final_velocity)
                            }
                            {(() => {
                              const calculatedQty = Math.round((item.reorder_point_days - item.total_fba_days_before + item.reorder_qty_days) * item.final_velocity);
                              if (Math.abs(item.adjusted_quantity - calculatedQty) > 1) {
                                if (item.adjusted_quantity > calculatedQty) {
                                  return ` (increased to ${item.adjusted_quantity} units to meet minimum manufacturing requirements)`;
                                } else if (item.adjusted_quantity < calculatedQty) {
                                  return ` (reduced to ${item.adjusted_quantity} units based on weekly limits)`;
                                }
                              }
                              return '';
                            })()}
                          </span>
                        </div>
                      </div>
                      {item.comments && (
                        <div className="comments">
                          {item.comments.split('\n').map((line, i) => (
                            <div key={i}>{line}</div>
                          ))}
                        </div>
                      )}
                    </div>
                    {renderBOMDetails(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }, [expandedBOMs, toggleBOMDetails, renderBOMDetails]);

  return (
    <ManufacturingPlanErrorBoundary>
      <div className="manufacturing-plan-section">
        <button 
          className="expand-button"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Hide' : 'Show'} Manufacturing Plan
        </button>

        {isExpanded && (
          <div className="plan-container">
            {plan && (
              <div className="plan-header">
                <div className="plan-header-info">
                  <span>
                    <strong>Created:</strong> {new Date(plan.created_at).toLocaleDateString()}
                  </span>
                  <span>
                    <strong>By:</strong> {plan.created_by}
                  </span>
                </div>
              </div>
            )}
            
            <div className="plan-controls">
              <input
                type="text"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
                placeholder="Plan Name"
                className="plan-input"
                disabled={loading}
              />
              <input
                type="number"
                value={maxUnitsPerWeek}
                onChange={(e) => setMaxUnitsPerWeek(parseInt(e.target.value))}
                placeholder="Max Units per Week"
                className="plan-input"
                disabled={loading}
                min="50"
              />
              <input
                type="number"
                value={numberOfWeeks}
                onChange={(e) => setNumberOfWeeks(parseInt(e.target.value))}
                min="1"
                max="8"
                className="plan-input"
                disabled={loading}
              />
              <label className="zero-velocity-toggle">
                <input
                  type="checkbox"
                  checked={includeZeroVelocity}
                  onChange={(e) => setIncludeZeroVelocity(e.target.checked)}
                  disabled={loading}
                />
                Include Zero Velocity Products
              </label>
              <label className="ignore-bom-toggle">
                <input
                  type="checkbox"
                  checked={ignoreBOMInventory}
                  onChange={(e) => setIgnoreBOMInventory(e.target.checked)}
                  disabled={loading}
                />
                Ignore BOM Inventory
              </label>
              <button 
                onClick={generatePlan}
                disabled={loading || !planName}
                className="generate-button"
              >
                {loading ? 'Generating...' : 'Generate Plan'}
              </button>
            </div>

            {error && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                {error}
              </div>
            )}

            {loading && !plan && (
              <div className="initial-loading">
                <LoadingSpinner />
                <span>Loading most recent plan...</span>
              </div>
            )}

            {plan && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <div className="weeks-container">
                  {loading && (
                    <div className="overlay">
                      <LoadingSpinner size="large" />
                    </div>
                  )}
                  {Array.from({ length: plan.number_of_weeks }, (_, i) => i + 1).map(weekNumber => (
                    renderWeek(
                      weekNumber,
                      plan.plan_items.filter(item => item.week_number === weekNumber)
                    )
                  ))}
                </div>
              </DragDropContext>
            )}
          </div>
        )}
      </div>
    </ManufacturingPlanErrorBoundary>
  );
};

export default ManufacturingPlan;