import api from './axiosConfig';

export const getBOMs = async (page = 1, limit = 100) => {
  try {
    const response = await api.get('/boms', {
      params: {
        skip: (page - 1) * limit,
        limit: limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching BOMs:', error);
    throw error;
  }
};
