import React, { useState, useEffect, useCallback, memo } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { fetchAsinDetails } from '../api/salesApi';
import DateRangePicker from './DateRangePicker';
import './AsinDetailModal.css';
import LoadingSpinner from './LoadingSpinner';
import { useDebounce } from '../hooks/useDebounce';
import { Confidential } from './Confidential';

const MetricCard = ({ title, value, format = 'number' }) => {
  const formatValue = (val) => {
    if (format === 'currency') {
      return `$${Math.abs(Number(val)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
    if (format === 'percent') {
      return `${Number(val).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`;
    }
    if (format === 'decimal') {
      return Number(val).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }
    return Number(val).toLocaleString();
  };

  return (
    <div className="metric-card">
      <h3>{title}</h3>
      <div className="metric-value">{formatValue(value)}</div>
    </div>
  );
};

const AsinDetailModal = ({ asin, onClose, initialDateRange }) => {
  const initialDates = React.useMemo(() => {
    if (initialDateRange && initialDateRange[0] && initialDateRange[1]) {
      return initialDateRange;
    }
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 6);
    return [start, end];
  }, [initialDateRange]);

  const [dateRange, setDateRange] = useState(initialDates);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const debouncedDateRange = useDebounce(dateRange, 1000);

  const [selectedMetrics, setSelectedMetrics] = useState({
    units_sold: true,
    in_stock: true,
    ppc_cost: true,
    profit: true,
    revenue: true,
    unit_session_percentage: false,
    conversion_rate: false,
    ctr: false
  });

  const handleDateRangeChange = useCallback((start, end) => {
    if (!start || !end) return;
    
    if (dateRange[0]?.getTime() === start.getTime() && 
        dateRange[1]?.getTime() === end.getTime()) {
      return;
    }
    
    console.log('AsinDetailModal: Date range changing from:', {
      oldStart: dateRange[0],
      oldEnd: dateRange[1],
      newStart: start,
      newEnd: end
    });
    
    setDateRange([start, end]);
  }, [dateRange]);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
    
    const fetchData = async () => {
      if (!debouncedDateRange[0] || !debouncedDateRange[1]) {
        console.log('AsinDetailModal: No date range available');
        return;
      }
      
      try {
        console.log('AsinDetailModal: Fetching data for dates:', {
          start: debouncedDateRange[0].toISOString(),
          end: debouncedDateRange[1].toISOString()
        });
        
        setLoading(true);
        const result = await fetchAsinDetails(
          asin, 
          debouncedDateRange[0], 
          debouncedDateRange[1], 
          controller.signal
        );
        
        if (mounted) {
          console.log('AsinDetailModal: Raw data received:', result);
          console.log('AsinDetailModal: Stock levels by date:', 
            result.daily_metrics.map(d => ({
              date: d.date,
              in_stock: d.in_stock
            }))
          );
          setData(result);
          setError(null);
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Request was cancelled');
          return;
        }
        if (mounted) {
          console.error('AsinDetailModal: Error fetching data:', err);
          setError(err.message || 'Failed to load ASIN details');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
      controller.abort();
    };
  }, [asin, debouncedDateRange]);

  const toggleMetric = (metric) => {
    setSelectedMetrics(prev => ({
      ...prev,
      [metric]: !prev[metric]
    }));
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  if (loading && !data) {
    return (
      <div className="asin-detail-modal">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>&times;</button>
          <LoadingSpinner message={`Loading data for ASIN ${asin}...`} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="asin-detail-modal">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>&times;</button>
          <div className="error">{error}</div>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="asin-detail-modal">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>&times;</button>
          <div>No data available</div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="asin-detail-modal" 
      onClick={(e) => e.target.className === 'asin-detail-modal' && onClose()}
    >
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        
        <div className="modal-header">
          <h2>ASIN Details: <Confidential>{asin}</Confidential></h2>
          <DateRangePicker
            onDateRangeChange={handleDateRangeChange}
            initialStartDate={initialDates[0]}
            initialEndDate={initialDates[1]}
            key={`${asin}-${initialDates[0].getTime()}-${initialDates[1].getTime()}`}
            forceInitialDates={true}
          />
        </div>

        {loading ? (
          <LoadingSpinner message="Updating data..." />
        ) : (
          <>
            <div className="metric-cards">
              <div className="metric-section">
                <h3>Unit Economics</h3>
                <div className="metric-grid">
                  {console.log('Unit Economics Values:', {
                    price: data.summary.price,
                    unit_cogs: data.summary.unit_cogs,
                    unit_fees: data.summary.unit_fees,
                    unit_net_profit: data.summary.unit_net_profit
                  })}
                  <MetricCard title="Price" value={data.summary.price} format="currency" />
                  <MetricCard title="Unit COGS" value={data.summary.unit_cogs} format="currency" />
                  <MetricCard title="Unit Fees" value={data.summary.unit_fees} format="currency" />
                  <MetricCard 
                    title="Unit Net Profit" 
                    value={data.summary.unit_net_profit} 
                    format="currency" 
                  />
                </div>
              </div>

              <div className="metric-section">
                <h3>Inventory</h3>
                <div className="metric-grid">
                  <MetricCard title="In Stock" value={data.summary.in_stock} />
                  <MetricCard title="Inbound" value={data.summary.inbound} />
                  <MetricCard title="Reserved" value={data.summary.reserved} />
                  <MetricCard title="Velocity" value={data.summary.velocity} format="decimal" />
                  <MetricCard title="Days of Supply" value={data.summary.days_of_supply} format="decimal" />
                </div>
              </div>

              <div className="metric-section">
                <h3>Sales Data</h3>
                <div className="metric-grid">
                  <MetricCard title="Total Sales" value={data.summary.total_sales} format="currency" />
                  <MetricCard title="COGS" value={data.summary.total_cogs} format="currency" />
                  <MetricCard title="Fees" value={data.summary.total_fees} format="currency" />
                  <MetricCard title="Net Profit" value={data.summary.total_profit} format="currency" />
                  <MetricCard title="Margin" value={data.summary.margin} format="percent" />
                </div>
              </div>

              <div className="metric-section">
                <h3>PPC</h3>
                <div className="metric-grid">
                  <MetricCard title="PPC Sales" value={data.summary.ppc_sales} format="currency" />
                  <MetricCard title="PPC Cost" value={data.summary.ppc_cost} format="currency" />
                  <MetricCard title="ACoS" value={data.summary.acos} format="percent" />
                  <MetricCard title="TACoS" value={data.summary.tacos} format="percent" />
                  <MetricCard title="Target ACoS" value={data.summary.target_acos} format="percent" />
                </div>
              </div>
            </div>

            <div className="chart-controls">
              <div className="metric-toggles">
                {Object.entries(selectedMetrics).map(([metric, isSelected]) => (
                  <label key={metric} className="metric-toggle">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => toggleMetric(metric)}
                    />
                    {metric.replace('_', ' ')}
                  </label>
                ))}
              </div>
            </div>

            <div className="chart-container">
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart 
                  data={data.daily_metrics} 
                  margin={{ top: 20, right: 30, bottom: 20, left: 30 }}
                  barGap={0}
                  barCategoryGap={20}
                >
                  <XAxis 
                    xAxisId="reference"
                    hide
                  />
                  
                  <XAxis 
                    dataKey="date" 
                    tickFormatter={(date) => {
                      const d = new Date(date);
                      d.setDate(d.getDate() + 1);
                      return `${d.getMonth() + 1}/${d.getDate()}/${String(d.getFullYear()).slice(-2)}`;
                    }}
                    height={50}
                    tick={{ fill: '#D1D5DB' }}
                    tickMargin={10}
                  />
                  
                  {/* Units Sold Y-axis (left) */}
                  {selectedMetrics.units_sold && (
                    <YAxis 
                      yAxisId="units_sold"
                      orientation="left"
                      label={{ 
                        value: 'Units Sold', 
                        angle: -90, 
                        position: 'insideLeft',
                        offset: -5,
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#4ade80"
                      tickMargin={8}
                    />
                  )}
                  
                  {/* In Stock Y-axis (left, offset) */}
                  {selectedMetrics.in_stock && (
                    <YAxis 
                      yAxisId="in_stock"
                      orientation="left"
                      label={{ 
                        value: 'In Stock', 
                        angle: -90, 
                        position: 'insideLeft',
                        offset: -5,
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#92400e"
                      tickMargin={8}
                      offset={80}
                    />
                  )}
                  
                  {/* PPC Cost Y-axis (right) */}
                  {selectedMetrics.ppc_cost && (
                    <YAxis 
                      yAxisId="ppc_cost"
                      orientation="right"
                      label={{ 
                        value: 'PPC Cost ($)', 
                        angle: 90, 
                        position: 'insideRight',
                        offset: 5,
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#ef4444"
                      tickMargin={8}
                    />
                  )}
                  
                  {/* Profit Y-axis (right, offset) */}
                  {selectedMetrics.profit && (
                    <YAxis 
                      yAxisId="profit"
                      orientation="right"
                      label={{ 
                        value: 'Profit ($)', 
                        angle: 90, 
                        position: 'insideRight',
                        offset: 5,
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#3b82f6"
                      tickMargin={8}
                      offset={80}
                    />
                  )}
                  
                  {/* Revenue Y-axis (right, double offset) */}
                  {selectedMetrics.revenue && (
                    <YAxis 
                      yAxisId="revenue"
                      orientation="right"
                      label={{ 
                        value: 'Revenue ($)', 
                        angle: 90, 
                        position: 'insideRight',
                        offset: 5,
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#a855f7"
                      tickMargin={8}
                      offset={160}
                    />
                  )}

                  {/* Update percentage metrics Y-axes with better scaling */}
                  {selectedMetrics.unit_session_percentage && (
                    <YAxis 
                      yAxisId="unit_session_percentage"
                      orientation="right"
                      label={{ 
                        value: 'Unit Session %', 
                        angle: 90, 
                        position: 'insideRight',
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#f97316"
                      tickFormatter={(value) => `${value.toFixed(2)}%`}
                      domain={[0, dataMax => Math.max(5, Math.ceil(dataMax * 1.1))]} // Ensure minimum scale of 5%
                      tickMargin={8}
                      offset={240}
                      allowDataOverflow={false}
                      scale="auto"
                    />
                  )}

                  {selectedMetrics.conversion_rate && (
                    <YAxis 
                      yAxisId="conversion_rate"
                      orientation="right"
                      label={{ 
                        value: 'Conversion %', 
                        angle: 90, 
                        position: 'insideRight',
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#14b8a6"
                      tickFormatter={(value) => `${value.toFixed(2)}%`}
                      domain={[0, dataMax => Math.max(5, Math.ceil(dataMax * 1.1))]} // Ensure minimum scale of 5%
                      tickMargin={8}
                      offset={320}
                      allowDataOverflow={false}
                      scale="auto"
                    />
                  )}

                  {selectedMetrics.ctr && (
                    <YAxis 
                      yAxisId="ctr"
                      orientation="right"
                      label={{ 
                        value: 'CTR %', 
                        angle: 90, 
                        position: 'insideRight',
                        style: { textAnchor: 'middle' }
                      }}
                      stroke="#ec4899"
                      tickFormatter={(value) => `${value.toFixed(2)}%`}
                      domain={[0, dataMax => {
                        const maxCtr = Math.max(...data.daily_metrics.map(d => d.ctr));
                        if (maxCtr < 1) {
                          return Math.ceil(maxCtr * 10) / 10;
                        }
                        return Math.ceil(maxCtr);
                      }]}
                      tickMargin={8}
                      offset={400}
                      allowDataOverflow={false}
                      scale="auto"
                      ticks={(() => {
                        const maxCtr = Math.max(...data.daily_metrics.map(d => d.ctr));
                        if (maxCtr < 1) {
                          const maxTick = Math.ceil(maxCtr * 10) / 10;
                          const tickCount = 5;
                          const tickInterval = maxTick / tickCount;
                          return Array.from({length: tickCount + 1}, (_, i) => 
                            Math.round((i * tickInterval) * 100) / 100);
                        }
                        return [0, 0.25, 0.5, 0.75, 1, 1.5, 2].filter(t => t <= Math.ceil(maxCtr));
                      })()}
                    />
                  )}

                  {/* Update Tooltip to handle percentage values */}
                  <Tooltip
                    formatter={(value, name) => {
                      if (['revenue', 'profit', 'ppc_cost'].includes(name)) {
                        return [`$${value.toLocaleString()}`, name];
                      }
                      if (['unit_session_percentage', 'conversion_rate', 'ctr'].includes(name)) {
                        return [`${value.toFixed(2)}%`, name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())];
                      }
                      return [value.toLocaleString(), name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())];
                    }}
                    labelFormatter={(date) => {
                      const d = new Date(date);
                      d.setDate(d.getDate() + 1);
                      return `${d.getMonth() + 1}/${d.getDate()}/${String(d.getFullYear()).slice(-2)}`;
                    }}
                  />
                  <Legend />

                  {/* Calculate bar size based on date range */}
                  {(() => {
                    const dayCount = data.daily_metrics.length;
                    // Adjust base size based on number of days
                    const baseSize = Math.max(10, Math.min(40, 800 / dayCount));
                    
                    return (
                      <>
                        {/* In Stock bars */}
                        {selectedMetrics.in_stock && (
                          <Bar 
                            dataKey="in_stock" 
                            name="In Stock" 
                            fill="#92400e"
                            yAxisId="in_stock"
                            fillOpacity={0.3}
                            barSize={baseSize} // Dynamic size
                          />
                        )}

                        {/* Units Sold bars */}
                        {selectedMetrics.units_sold && (
                          <Bar 
                            dataKey="units_sold" 
                            name="Units Sold" 
                            fill="#4ade80"
                            yAxisId="units_sold"
                            fillOpacity={0.8}
                            barSize={baseSize} // Same size as in_stock
                          />
                        )}
                      </>
                    );
                  })()}

                  {/* Financial metric lines */}
                  {selectedMetrics.ppc_cost && (
                    <Line 
                      dataKey="ppc_cost" 
                      name="PPC Cost" 
                      stroke="#ef4444"
                      yAxisId="ppc_cost"
                      dot={false}
                      strokeWidth={2}
                    />
                  )}
                  {selectedMetrics.profit && (
                    <Line 
                      dataKey="profit" 
                      name="Profit" 
                      stroke="#3b82f6"
                      yAxisId="profit"
                      dot={false}
                      strokeWidth={2}
                    />
                  )}
                  {selectedMetrics.revenue && (
                    <Line 
                      dataKey="revenue" 
                      name="Revenue" 
                      stroke="#a855f7"
                      yAxisId="revenue"
                      dot={false}
                      strokeWidth={2}
                    />
                  )}

                  {/* Update percentage metric lines */}
                  {selectedMetrics.unit_session_percentage && (
                    <Line 
                      dataKey="unit_session_percentage"
                      name="Unit Session %"
                      stroke="#f97316"
                      yAxisId="unit_session_percentage"
                      dot={false}
                      strokeWidth={2}
                      connectNulls={true}
                      isAnimationActive={false}
                    />
                  )}

                  {selectedMetrics.conversion_rate && (
                    <Line 
                      dataKey="conversion_rate"
                      name="Conversion %"
                      stroke="#14b8a6"
                      yAxisId="conversion_rate"
                      dot={false}
                      strokeWidth={2}
                      connectNulls={true}
                      isAnimationActive={false}
                    />
                  )}

                  {selectedMetrics.ctr && (
                    <Line 
                      dataKey="ctr"
                      name="CTR %"
                      stroke="#ec4899"
                      yAxisId="ctr"
                      dot={false}
                      strokeWidth={2}
                      connectNulls={true}
                      isAnimationActive={false}
                    />
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(AsinDetailModal); 